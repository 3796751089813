require("jquery")
require("bootstrap")
import 'bootstrap-colorpicker'
require('../stylesheets/admin.scss')

import {default as timeago} from "packs/vendor/timeago.js";
import {default as fileuploader} from "packs/vendor/fileuploader.min.js";

// Attention: Need to keep this for DELETE links to be working
Rails.start();

$(document).ready(function(){
    var window_width = $(window).width();

    // We put modals out of wrapper to working properly
    $('.modal').appendTo("body");

    $('.form-control').on("focus", function(){
        $(this).parent('.input-group').addClass("input-group-focus");
    }).on("blur", function(){
        $(this).parent(".input-group").removeClass("input-group-focus");
    });

    //  Activate the tooltips
    $('[rel="tooltip"]').tooltip();

    //  Init Bootstrap Select Picker
    if($(".selectpicker").length != 0){
        $(".selectpicker").selectpicker();
    }

    jQuery.timeago.settings.strings = {
        prefixAgo: "vor",
        prefixFromNow: "in",
        suffixAgo: "",
        suffixFromNow: "",
        seconds: "wenigen Sekunden",
        minute: "etwa einer Minute",
        minutes: "%d Minuten",
        hour: "etwa einer Stunde",
        hours: "%d Stunden",
        day: "etwa einem Tag",
        days: "%d Tagen",
        month: "etwa einem Monat",
        months: "%d Monaten",
        year: "etwa einem Jahr",
        years: "%d Jahren"
    };

    jQuery("abbr.timeago").timeago();

    $('.btn-clipboard').click(function () {
        var text = $(this).data('clipboard');
        if (copyToClipboard(text))
            alert('Ihr Termin-Link wurde erfolgreich in Ihre Zwischenablage kopiert!');
        else
            alert('Leider scheint Ihr Browser das automatische Kopieren nicht zu unterstützen. Bitte kopieren Sie den Link manuell.');
    });

    $('.custom-file-input').on('change',function(){
        var fileName = $(this).val().split('\\').pop();
        $(this).next('label').html(fileName);
    });

    $('.navbar-toggler').click(function (){
        $('html').toggleClass('nav-open');
    });

    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===
    // Support page
    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===


    var ToC =
        "<nav role='navigation' class='table-of-contents'>" +
        "<ul>";

    var newLine, el, title, link;

    $(".faq-question").each(function() {

        el = $(this);
        title = el.text();
        link = "#" + el.attr("id");

        newLine =
            "<li>" +
            "<a class='primary' href='" + link + "'>" +
            title +
            "</a>" +
            "</li>";

        ToC += newLine;

    });

    ToC +=
        "</ul>" +
        "</nav>";

    $("#toc").prepend(ToC);

    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===
    // Account settings page
    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===

    var url = document.location.toString();
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    }

    // Change hash for page-reload
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        if(history.pushState) {
            history.pushState(null, null, e.target.hash);
        } else {
            window.location.hash = e.target.hash; //Polyfill for old browsers
        }
    })

    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===
    // Appearance page
    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===

    $('#meetme_background_color').change(function () {
        $('#previewImage').css("background-color", $('#meetme_background_color').val());
    });

    $('.colorpicker-component').colorpicker(
        {
            extensions: [
                {
                    name: 'swatches',
                    options:
                        {
                            colors: {
                                'default': '#9D3039',
                                'blue': '#356d98',
                                'grey': '#484848',
                                'orange': '#d09e3b',
                                'lila': '#693860'
                            },
                            namesAsValues: false
                        }
                }
            ]
        }
    );

    var logo_url = $('input.companylog_file_upload').data('company-logo-url');
    var profileimage_url = $('input.profileimage_file_upload').data('profileimage-url');

    $('input.profileimage_file_upload').fileuploader({
        limit: 1,
        extensions: ['jpg', 'jpeg', 'png', 'gif'],
        theme: 'thumbnails',
        changeInput: ' ',
        enableApi: true,
        addMore: true,
        captions: {
            button: function (options) {
                return 'Wählen Sie ' + (options.limit == 1 ? 'eine Datei' : 'mehrere Dateien');
            },
            feedback: function (options) {
                return 'Wählen Sie ' + (options.limit == 1 ? 'eine Datei' : 'mehrere Dateien') + ' zum hochladen';
            },
            feedback2: function (options) {
                return options.length + ' ' + (options.length > 1 ? ' Dateien waren' : ' Datei wurde') + ' gewählt';
            },
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            name: 'Name',
            type: 'Dateityp',
            size: 'Größe',
            dimensions: 'Maße',
            duration: 'Dauer',
            crop: 'Zuschneiden',
            rotate: 'Drehen',
            sort: 'Sortieren',
            download: 'Download',
            remove: 'Entfernen',
            drop: 'Datei zum Upload hierher ziehen',
            paste: '<div class="fileuploader-pending-loader"></div> Pasting a file, click here to cancel.',
            removeConfirmation: 'Sind Sie sicher, dass Sie diese Datei entfernen möchten?',
            errors: {
                filesLimit: 'Es können maximal ${limit} Dateien hochgeladen werden.',
                filesType: 'Es werden ausschließlich die Dateitypen ${extensions} unterstützt.',
                fileSize: 'Die Datei ${name} ist zu groß. Bitte wählen Sie eine Datei kleiner als ${fileMaxSize}MB.',
                filesSizeAll: 'Die gewählten Dateien sind zu groß. Bitte wählen Sie Datein kleiner als ${maxSize} MB.',
                fileName: 'Die Datei ${name} wurde bereits ausgewählt.',
                folderUpload: 'Order können leider nicht hochgeladen werden.'
            }
        },
        onFileRead: function (item, listEl, parentEl, newInputEl, inputEl) {
            console.log(item, listEl, parentEl, newInputEl, inputEl);
            var reader = new FileReader();
            reader.onload = function (e) {
                $('#previewImageProfile').fadeIn().attr('src', e.target.result);
            };
            reader.readAsDataURL(item.file);
        },
        thumbnails: {
            box: '<div class="fileuploader-items">' +
                '<ul class="fileuploader-items-list">' +
                '<li class="fileuploader-thumbnails-input"><div class="fileuploader-thumbnails-input-inner">' +
                '<img style="max-height: 100%" src="' + profileimage_url + '"/>' +
                '</div></li>' +
                '</ul>' +
                '</div>',
            item: '<li class="fileuploader-item file-has-popup">' +
                '<div class="fileuploader-item-inner">' +
                '<div class="type-holder">${extension}</div>' +
                '<div class="actions-holder">' +
                '<a class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i></i></a>' +
                '</div>' +
                '<div class="thumbnail-holder">' +
                '${image}' +
                '<span class="fileuploader-action-popup"></span>' +
                '</div>' +
                '<div class="content-holder"><h5>${name}</h5><span>${size2}</span></div>' +
                '<div class="progress-holder">${progressBar}</div>' +
                '</div>' +
                '</li>',
            item2: '<li class="fileuploader-item file-has-popup">' +
                '<div class="fileuploader-item-inner">' +
                '<div class="type-holder">${extension}</div>' +
                '<div class="actions-holder">' +
                '<a href="${file}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i></i></a>' +
                '<a class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i></i></a>' +
                '</div>' +
                '<div class="thumbnail-holder">' +
                '${image}' +
                '<span class="fileuploader-action-popup"></span>' +
                '</div>' +
                '<div class="content-holder"><h5>${name}</h5><span>${size2}</span></div>' +
                '<div class="progress-holder">${progressBar}</div>' +
                '</div>' +
                '</li>',
            startImageRenderer: true,
            canvasImage: false,
            _selectors: {
                list: '.fileuploader-items-list',
                item: '.fileuploader-item',
                start: '.fileuploader-action-start',
                retry: '.fileuploader-action-retry',
                remove: '.fileuploader-action-remove'
            },
            onItemShow: function (item, listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                plusInput.insertAfter(item.html)[api.getOptions().limit && api.getChoosedFiles().length >= api.getOptions().limit ? 'hide' : 'show']();

                if (item.format == 'image') {
                    item.html.find('.fileuploader-item-icon').hide();
                }
            },
            onItemRemove: function (html, listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                html.children().animate({'opacity': 0}, 200, function () {
                    setTimeout(function () {
                        html.remove();

                        if (api.getFiles().length - 1 < api.getOptions().limit) {
                            plusInput.show();
                        }
                    }, 100);
                });

            }
        },
        dragDrop: {
            container: '.fileuploader-thumbnails-input'
        },
        afterRender: function (listEl, parentEl, newInputEl, inputEl) {
            var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                api = $.fileuploader.getInstance(inputEl.get(0));

            plusInput.on('click', function () {
                api.open();
            });
        }

    })

    $('input.companylog_file_upload').fileuploader({
        limit: 1,
        extensions: ['jpg', 'jpeg', 'png', 'gif'],
        theme: 'thumbnails',
        changeInput: ' ',
        enableApi: true,
        addMore: true,
        captions: {
            button: function (options) {
                return 'Wählen Sie ' + (options.limit == 1 ? 'eine Datei' : 'mehrere Dateien');
            },
            feedback: function (options) {
                return 'Wählen Sie ' + (options.limit == 1 ? 'eine Datei' : 'mehrere Dateien') + ' zum hochladen';
            },
            feedback2: function (options) {
                return options.length + ' ' + (options.length > 1 ? ' Dateien waren' : ' Datei wurde') + ' gewählt';
            },
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            name: 'Name',
            type: 'Dateityp',
            size: 'Größe',
            dimensions: 'Maße',
            duration: 'Dauer',
            crop: 'Zuschneiden',
            rotate: 'Drehen',
            sort: 'Sortieren',
            download: 'Download',
            remove: 'Entfernen',
            drop: 'Datei zum Upload hierher ziehen',
            paste: '<div class="fileuploader-pending-loader"></div> Pasting a file, click here to cancel.',
            removeConfirmation: 'Sind Sie sicher, dass Sie diese Datei entfernen möchten?',
            errors: {
                filesLimit: 'Es können maximal ${limit} Dateien hochgeladen werden.',
                filesType: 'Es werden ausschließlich die Dateitypen ${extensions} unterstützt.',
                fileSize: 'Die Datei ${name} ist zu groß. Bitte wählen Sie eine Datei kleiner als ${fileMaxSize}MB.',
                filesSizeAll: 'Die gewählten Dateien sind zu groß. Bitte wählen Sie Datein kleiner als ${maxSize} MB.',
                fileName: 'Die Datei ${name} wurde bereits ausgewählt.',
                folderUpload: 'Order können leider nicht hochgeladen werden.'
            }
        },
        onFileRead: function (item, listEl, parentEl, newInputEl, inputEl) {
            console.log(item, listEl, parentEl, newInputEl, inputEl);
            var reader = new FileReader();
            reader.onload = function (e) {
                $('#previewImageCompanyLogo').fadeIn().attr('src', e.target.result);
            };
            reader.readAsDataURL(item.file);
        },
        thumbnails: {
            box: '<div class="fileuploader-items">' +
                '<ul class="fileuploader-items-list">' +
                '<li class="fileuploader-thumbnails-input"><div class="fileuploader-thumbnails-input-inner">' +
                '<img style="max-height: 100%" src="' + logo_url + '"/>' +
                '</div></li>' +
                '</ul>' +
                '</div>',
            item: '<li class="fileuploader-item file-has-popup">' +
                '<div class="fileuploader-item-inner">' +
                '<div class="type-holder">${extension}</div>' +
                '<div class="actions-holder">' +
                '<a class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i></i></a>' +
                '</div>' +
                '<div class="thumbnail-holder">' +
                '${image}' +
                '<span class="fileuploader-action-popup"></span>' +
                '</div>' +
                '<div class="content-holder"><h5>${name}</h5><span>${size2}</span></div>' +
                '<div class="progress-holder">${progressBar}</div>' +
                '</div>' +
                '</li>',
            item2: '<li class="fileuploader-item file-has-popup">' +
                '<div class="fileuploader-item-inner">' +
                '<div class="type-holder">${extension}</div>' +
                '<div class="actions-holder">' +
                '<a href="${file}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i></i></a>' +
                '<a class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i></i></a>' +
                '</div>' +
                '<div class="thumbnail-holder">' +
                '${image}' +
                '<span class="fileuploader-action-popup"></span>' +
                '</div>' +
                '<div class="content-holder"><h5>${name}</h5><span>${size2}</span></div>' +
                '<div class="progress-holder">${progressBar}</div>' +
                '</div>' +
                '</li>',
            startImageRenderer: true,
            canvasImage: false,
            _selectors: {
                list: '.fileuploader-items-list',
                item: '.fileuploader-item',
                start: '.fileuploader-action-start',
                retry: '.fileuploader-action-retry',
                remove: '.fileuploader-action-remove'
            },
            onItemShow: function (item, listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                plusInput.insertAfter(item.html)[api.getOptions().limit && api.getChoosedFiles().length >= api.getOptions().limit ? 'hide' : 'show']();

                if (item.format == 'image') {
                    item.html.find('.fileuploader-item-icon').hide();
                }
            },
            onItemRemove: function (html, listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                html.children().animate({'opacity': 0}, 200, function () {
                    setTimeout(function () {
                        html.remove();

                        if (api.getFiles().length - 1 < api.getOptions().limit) {
                            plusInput.show();
                        }
                    }, 100);
                });

            }
        },
        dragDrop: {
            container: '.fileuploader-thumbnails-input'
        },
        afterRender: function (listEl, parentEl, newInputEl, inputEl) {
            var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                api = $.fileuploader.getInstance(inputEl.get(0));

            plusInput.on('click', function () {
                api.open();
            });
        }

    });

    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===
    // Login page
    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===

    $('#loginResetSoftLoginUser').click(function () {
        $('#emailField').removeClass('d-none'); $(this).hide();
    });

    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===
    // Clickable Lists
    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===
    $("tr.clickable-row").click(function() {
        window.location.href = $(this).find("a.showlink").attr("href");
    });
    $('.dropdown').click(function(){
        $('.dropdown-menu').toggleClass('show');
    });

    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===
    // INIT Get Ready Wizard
    // === === === === === === === === === === === === === === === === === === === === === === === === === === === ===

    if(typeof steps !== "undefined") getReadyWizard.init($('#get-ready-wizard-container'), steps);

});


// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and IE 10+.
// IE: The clipboard feature may be disabled by an administrator. By
// default a prompt is shown the first time the clipboard is
// used (per session).
function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return clipboardData.setData("Text", text);

    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }
}


// === === === === === === === === === === === === === === === === === === === === === === === === === === === ===
// Get Ready Wizard
// === === === === === === === === === === === === === === === === === === === === === === === === === === === ===

var getReadyWizard = (function () {

    var root;
    var steps;

    var $html = $('<div class="card get-ready-wizard">' +
        '      <div class="card-content">' +
        '        <div class="text-center">' +
        '          <h3>In wenigen Schritten zum persönlichen Terminassistenten</h3>' +
        '          <p>Um den Terminfinder nutzen zu könnnen, stellen Sie sicher, dass Sie die folgenden Schritte der Reihe nach abhaken:</p>' +
        '          <br>' +
        '        </div>' +
        '        <div class="row">' +
        '          <div class="col-md-5">' +
        '            <ul id="get-ready-todos">' +
        '            </ul>' +
        '          </div>' +
        '          <div class="col-md-7" id="get-ready-step-details">' +
        '          </div>' +
        '        </div>' +
        '      </div>' +
        '      <div class="card-footer"></div>' +
        '    </div>');

    var $finished = '<div class="fa">        <h1 style="color: #55e2a5"><span class="glyphicon glyphicon-ok"></span></h1>        <h3>Einrichtung erfolgreich abgeschlossen</h3>        <p>Glückwunsch! Sie haben die Einrichtung Ihres Terminassistenten abgeschlossen</p></div>';

    var init = function init(_root, _steps) {
        root = _root;
        steps = _steps;

        $(root).prepend($html);
        $.each(steps, function (i, step) {
            $('<li class="step '+(step.done ? 'done' : '')+'">' +
                '<span class="status">' +
                (step.done ? '<span class="glyphicon glyphicon-ok"></span>' : '<i class="'+step.iconClass+'"></i>') +
                '</span>' +
                '<span class="count">'+step.index+'</span>' +
                '<h5>'+step.title+'</h5>' +
                '</li>')
                .appendTo(root.find('ul#get-ready-todos'))
                .click(onStepChange)
                .data('details', step.html);
        });

        setup();
    };

    var onStepChange = function() {
        root.find('ul#get-ready-todos .step').removeClass('active');
        $(this).addClass('active');
        loadDetailsHTML($(this).data('details'));
    };

    var loadDetailsHTML = function(html) {

        root.find('#get-ready-step-details').hide().html(html).fadeIn(300);
    };

    var setup = function () {
        var nextStep = root.find('.step:not(.done)').first();
        if(nextStep.length === 0) {
            loadDetailsHTML($finished);
        } else {
            nextStep.click();
        }
    };

    return {
        init: init
    };

})();

